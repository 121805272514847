
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { FormInput, FormCheckbox, FormSelect, FormPhone } from "@/components/forms";
import { mask } from "vue-the-mask";
import { countryOptions, companyTypes, titleOptions } from "@/utils/select-options";

interface CompanyRepresentative {
    not_all_information_known: boolean;
    company: string;
    company_type: string;
    company_address: string;
    title: string;
    first_name: string;
    last_name: string;
    national_register_number: string;
    birth_place: string;
    birth_date: string;
    email: string;
    phone: string;
    vat: string;
    address: {
        street: string;
        number: string | null;
        additional: string | null;
        zip: string;
        city: string;
        country: string;
    };
}

@Component({
    components: {
        FormInput,
        FormCheckbox,
        FormSelect,
        FormPhone,
    },
    directives: { mask },
})
export default class FormCompanyRepresentatives extends Vue {
    @Prop() value?: CompanyRepresentative[] | null;

    @Prop({ required: true }) name!: string;

    @Prop({ default: false }) label!: string;

    @Prop({ default: false }) disabled!: boolean;

    defaultAddress!: { street: string; number: string | null; additional: string | null; zip: string; city: string; country: string };

    countryOptions = countryOptions;

    companyTypes = companyTypes;

    titleOptions = titleOptions;

    localValue: CompanyRepresentative[] = [
        {
            not_all_information_known: false,
            company: "",
            company_type: "",
            company_address: "",
            title: "",
            first_name: "",
            last_name: "",
            national_register_number: "",
            birth_place: "",
            birth_date: "",
            phone: "",
            email: "",
            vat: "",
            address: {
                street: "",
                number: "",
                additional: "",
                zip: "",
                city: "",
                country: "",
            },
        },
    ];

    addRepresentative() {
        this.localValue!.push({
            not_all_information_known: false,
            company: "",
            vat: "",
            company_type: "",
            company_address: "",
            title: "",
            first_name: "",
            last_name: "",
            national_register_number: "",
            birth_place: "",
            birth_date: "",
            phone: "",
            email: "",
            address: {
                street: "",
                number: "",
                additional: "",
                zip: "",
                city: "",
                country: "",
            },
        });
    }

    removeRepresentative(index: number) {
        this.localValue!.splice(index, 1);
    }

    @Emit("input")
    handleInput(v: boolean) {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: CompanyRepresentative[] | null) {
        this.localValue = newValue ?? [
            {
                not_all_information_known: false,
                company: "",
                vat: "",
                company_type: "",
                company_address: "",
                title: "",
                first_name: "",
                last_name: "",
                national_register_number: "",
                birth_place: "",
                birth_date: "",
                phone: "",
                email: "",
                address: {
                    street: "",
                    number: "",
                    additional: "",
                    zip: "",
                    city: "",
                    country: "",
                },
            },
        ];
    }
}
